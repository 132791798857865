<template>
  <div class="topbg-wrapper">
    <div class="top-bg">
      <header-search />
    </div>
    <div class="block-detial-box">
      <el-card class="box-card" v-if="blockInfo">
        <div slot="header">
          <div class="card-header">
            {{ $t("區塊") }}
          </div>
        </div>
        <div class="item flex-middle">
          <div class="title">{{ $t("區塊高度") }}:</div>
          <div class="value">
            <el-input-number
              v-model="blockNumber"
              size="small"
              controls-position="right"
              @change="currentChange"
              :min="1"
              :max="maxBlockNumber"
            ></el-input-number>
          </div>
        </div>
        <div class="item flex-middle">
          <div class="title">{{ $t("產生時間") }}:</div>
          <div class="value">
            {{
              dateFormat(
                "yyyy-MM-dd hh:mm:ss",
                new Date(blockInfo.timestamp * 1000 || 0)
              )
            }}
          </div>
        </div>
        <div class="item flex-middle">
          <div class="title">{{ $t("交易次數") }}:</div>
          <div class="value">
            {{ blockInfo.transactionNum }}{{ $t("個交易在這個區塊中") }}
          </div>
        </div>
        <div class="item flex-middle">
          <div class="title">{{ $t("礦工") }}:</div>
          <div class="value">{{ blockInfo.miner }}</div>
        </div>
        <div class="item flex-middle">
          <div class="title">{{ $t("難度值") }}:</div>
          <div class="value">{{ blockInfo.difficulty }}</div>
        </div>
        <div class="item flex-middle">
          <div class="title">{{ $t("總難度值") }}:</div>
          <div class="value">{{ blockInfo.totalDifficulty }}</div>
        </div>
        <div class="item flex-middle">
          <div class="title">{{ $t("容量") }}:</div>
          <div class="value">{{ blockInfo.size }}</div>
        </div>
        <div class="item flex-middle">
          <div class="title">{{ $t("燃料限制") }}:</div>
          <div class="value">{{ blockInfo.gasLimit }}</div>
        </div>
        <div class="item flex-middle">
          <div class="title">{{ $t("額外數據") }}:</div>
          <div class="value">{{ blockInfo.extraData }}</div>
        </div>
        <div class="item flex-middle">
          <div class="title">{{ $t("哈希") }}:</div>
          <div class="value">{{ blockInfo.hash }}</div>
        </div>
        <div class="item flex-middle">
          <div class="title">{{ $t("父區塊哈希") }}:</div>
          <div class="value">{{ blockInfo.parentHash }}</div>
        </div>
        <div class="item flex-middle">
          <div class="title">(Sha3){{ $t("叔區塊") }}:</div>
          <div class="value">{{ blockInfo.sha3Uncles }}</div>
        </div>
        <div class="item flex-middle">
          <div class="title">nonce:</div>
          <div class="value">{{ blockInfo.nonce }}</div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import { useRouter, useRoute, onBeforeRouteUpdate } from "vue-router";
import { getBlockInfo, getBlockNumber } from "@/api";
import HeaderSearch from "@/components/HeaderSearch";

export default {
  components: {
    HeaderSearch
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const data = reactive({
      blockNumber: Number(route.params.blockNumber),
      maxBlockNumber: 10000000000000,
      blockInfo: null,
      currentChange: number => {
        router.replace({
          name: "BlockDetial",
          params: { blockNumber: number }
        });
      }
    });
    onMounted(() => {
      getBlockNumber().then(res => {
        data.maxBlockNumber = res.data;
      });
      getBlockInfo({ blockNumber: data.blockNumber }).then(res => {
        data.blockInfo = res.data;
      });
    });
    onBeforeRouteUpdate((to, from) => {
      data.blockNumber = Number(to.params.blockNumber);
      data.blockInfo = null;
      getBlockInfo({ blockNumber: data.blockNumber }).then(res => {
        data.blockInfo = res.data;
      });
      getBlockNumber().then(res => {
        data.maxBlockNumber = res.data + "";
      });
    });
    const refData = toRefs(data);
    return {
      ...refData
    };
  }
};
</script>

<style lang="scss" scoped>
.block-detial-box {
  max-width: 1400px;
  margin: -100px auto 80px;
  box-shadow: 0px 4px 12px 0px rgba(10, 15, 23, 0.02);
  border-radius: 20px;
  .el-card {
    border-radius: 20px !important;
  }
  .el-card__body {
    border-radius: 20px;
  }
}
.card-header {
  font-size: 18px;
  font-weight: 700;
  text-align: left;
}
.item {
  font-size: 16px;
  margin-top: 20px;
  color: #0a0f17;
  text-align: left;
  .title {
    width: 20%;
    color: #666;
  }
  .value {
    flex: 1;
    word-break: break-all;
  }
}
@media screen and (max-width: 480px) {
  .block-detial-box {
    margin: -20px 15px 20px;
    border-radius: 10px;
    .el-card__body {
      border-radius: 10px;
    }
  }
  .card-header {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
  }
  .item,
  .subset-wrap {
    font-size: 16px;
    margin-top: 20px;
    color: #0a0f17;
    text-align: left;
    .title {
      min-width: 100px;
      color: #666;
    }
    .value {
      flex: 1;
      word-break: break-all;
    }
  }
}
</style>
